header{
    height: 118vh;
    padding-top: 3rem;
    overflow: hidden;
    margin-top: -120px;
}
.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}
/* ===== CTA ===== */
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
/* ===== HEADER SOCIALS ===== */
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header__socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
/* ===== ME ===== */
.me{
    width: 26rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 13rem);
    border-radius: 14rem 14rem 0 0;
    margin-top:4rem;
    overflow: hidden;
}
/* ===== SCROLL DOWN ===== */
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 8rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}
/* ===== MEDIA QUERIES (MEDIUM DEVCES) ==== */
@media screen and (max-width: 1024px){
    header{
        height: 80vh;
    }
}
/* ===== MEDIA QUERIES (SMALL DEVCES) ==== */
@media screen and (max-width: 600px){
    header{
        height: 100vh;
    }
    .header__socials,.scroll__down{
        display: none;
    }
    
}
